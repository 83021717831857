import { useState } from 'react';
import { MainCharacterEditorPage } from './MainCharacterEditorPage';
import { Box, Button } from '@mui/joy';
import { FullPage } from './FullPage';
import { KeyboardArrowRight } from '@mui/icons-material';
import { DetailsConfirmationPage } from './DetailsConfirmationPage';
import EnemiesEditorPage from './EnemiesEditorPage';

export function CustomizePageManager() {
    const [currentPage, setCurrentPage] = useState(0);

    function nextPage() {
        setCurrentPage(Math.min(currentPage + 1, pages.length - 1));
    }

    function previousPage() {
        setCurrentPage(Math.max(0, currentPage - 1));
    }

    const pages = [
        () => <MainCharacterEditorPage />,
        () => <EnemiesEditorPage />,
        () => <DetailsConfirmationPage />,
    ];

    return (
        <FullPage>
            <Box>{pages[currentPage]()}</Box>
            <Box display="flex" justifyContent="space-between">
                <Button onClick={previousPage} variant="plain" color="neutral">
                    Back
                </Button>
                <Button
                    onClick={nextPage}
                    endDecorator={<KeyboardArrowRight />}
                >
                    Next
                </Button>
            </Box>
        </FullPage>
    );
}
