export type FilePath = string;
export type FileType = 'jpg' | 'mp3' | 'png';
export type ExportId = string;

export interface Base64Resource {
    base64Content: string;
    type: FileType;
}

export interface MediaFileNameResource {
    source: string;
    type: FileType;
}

export interface MediaFileResource {
    source: File;
    type: FileType;
}

export interface ResourcePackOptions {
    enemies: EnemyOptions[];
    player: Player | null;
    world: World;
}

export interface EnemyOptions {
    texture: MediaFileResource | null;
    sound: MediaFileResource | null;
    spawnProbability: number | null;
}

export interface Player {
    texture: MediaFileResource;
}

export interface World {
    background: MediaFileResource[];
    backgroundSound: MediaFileResource[];
    groundTexture: MediaFileResource | null;
}

export function isMediaSourceResource(
    object: any,
): object is MediaFileResource {
    return object?.source instanceof File && object?.type !== undefined;
}
