import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import { CustomizePageManager } from './pages/CustomizePageManager';
import { ThemeProvider } from '@mui/joy';

function App() {
    return (
        <CssVarsProvider defaultMode="light">
            <ThemeProvider>
                <center style={{
                    color: "red",
                }}>
                <h2>Warning</h2> 
                <p>
                    This webpage is in active development and only to be used for testing by <i>authorized personnel</i>.
                    While we do not employ tracking services, be aware that any data you upload may be stored on our server temporarily.
                </p>
                <p style={{color: "black"}}>
                    We take care not to store your IP address, browser fingerprint or other identifying data, unless you deliberately upload images in which case we store only the image. You have been warned.
                </p>
                </center>
                <CustomizePageManager />
            </ThemeProvider>
        </CssVarsProvider>
    );
}

export default App;
