import {
    Box,
    Card,
    CardActions,
    CardContent,
    IconButton,
    styled,
    Typography,
} from '@mui/joy';
import DinoGif from '../assets/dino.gif';
import { Delete } from '@mui/icons-material';
import { useStore } from '../store';
import ExtendedImageUploadButton from './ExtendedImageUploadButton';
import Texture from './Texture';

export const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`;

export function MainCharacterEditorPage() {
    const {
        setPlayerTexture,
        resetMainCharacterAvatar,
        options: { player },
    } = useStore();

    return (
        <Card color="neutral" orientation="vertical" size="lg" variant="soft">
            <CardContent>
                <Texture
                    alt={DinoGif}
                    texture={player?.texture}
                    size={'10rem'}
                />
                <Box paddingTop="1rem">
                    <Typography level="title-lg">
                        Main Character Avatar
                    </Typography>
                    <Typography level="body-sm">
                        Customize the avatar of the Dino, the most important
                        character in the game. Make sure it's cute!
                    </Typography>
                </Box>
            </CardContent>
            <CardActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <ExtendedImageUploadButton onChange={setPlayerTexture} />
                    <IconButton onClick={() => resetMainCharacterAvatar()}>
                        <Delete />
                    </IconButton>
                </Box>
            </CardActions>
        </Card>
    );
}
