import { useStore } from '../store';
import { Box, Button } from '@mui/joy';
import {
    isMediaSourceResource,
} from '../common/godot.types';
import { compilePackAndGame, getGamePreviewPath } from '../client';

export function iterateObjectRecursively(
    obj: object,
    callback: (value: object) => void,
): void {
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            callback(value);
            if (typeof value === 'object' && value !== null) {
                iterateObjectRecursively(value, callback);
            }
        }
    }
}

export function DetailsConfirmationPage() {
    const { options } = useStore();
    
    async function onClick() {
        const exportId = await compilePackAndGame(options);
        window.location.href = getGamePreviewPath(exportId);
    }

    const text = JSON.stringify(
        options,
        (_, value) => {
            if (isMediaSourceResource(value)) {
                console.log(value);
                return `${value.source.name} (type: ${value.type})`;
            }
            return value;
        },
        4,
    );

    return (
        <Box>
            <pre>
                {text}
            </pre>
            <Button onClick={onClick}>Generate game</Button>
        </Box>
    );
}
