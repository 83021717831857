import { Box } from '@mui/joy';

export function FullPage({ children }: { children: React.ReactNode }) {
    return (
        <Box
            display="flex"
            height="calc(100% - 2rem)"
            width="calc(100% - 2rem)"
            flexDirection="column"
            justifyContent="space-between"
            margin="1rem"
        >
            {children}
        </Box>
    );
}
