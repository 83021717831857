import { useStore } from '../store';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    IconButton,
    ToggleButtonGroup,
} from '@mui/joy';
import Texture from './Texture';
import { Delete } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import ExtendedImageUploadButton from './ExtendedImageUploadButton';

export default function EnemiesEditorPage() {
    const {
        options: { enemies },
        changeEnemySpawnProbability,
        changeEnemyTexture,
        deleteEnemy,
    } = useStore();

    const [showAddEnemy, setShowAddEnemy] = useState(enemies.length === 0);

    useEffect(() => {
        setShowAddEnemy(enemies.length === 0);
    }, [enemies.length]);

    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing(2),
                flexWrap: 'wrap',
                justifyContent: 'center',
                flexDirection: 'column',
            })}
        >
            {(showAddEnemy
                ? [
                      ...enemies,
                      {
                          texture: null,
                          spawnProbability: 0,
                      },
                  ]
                : enemies
            ).map((enemy, index) => (
                <Card
                    color="neutral"
                    orientation="vertical"
                    size="lg"
                    variant="soft"
                    key={index}
                >
                    <CardContent>
                        <Texture texture={enemy.texture} size={'10rem'} />
                        <ToggleButtonGroup
                            size="lg"
                            value={enemy.spawnProbability}
                            onChange={(event, newValue) => {
                                changeEnemySpawnProbability(index, newValue);
                            }}
                        >
                            <Button value={10}>Easter Egg</Button>
                            <Button value={15}>Rare</Button>
                            <Button value={70}>Common</Button>
                        </ToggleButtonGroup>
                    </CardContent>
                    <CardActions>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <ExtendedImageUploadButton
                                onChange={(file) =>
                                    changeEnemyTexture(index, file)
                                }
                            />
                            <IconButton
                                onClick={() => {
                                    if (index >= enemies.length) {
                                        setShowAddEnemy(false);
                                    } else {
                                        deleteEnemy(index);
                                    }
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    </CardActions>
                </Card>
            ))}
            {showAddEnemy ? null : (
                <Button onClick={() => setShowAddEnemy(true)}>Add enemy</Button>
            )}
        </Box>
    );
}
