import { ExportId, MediaFileNameResource, ResourcePackOptions, isMediaSourceResource } from "./common/godot.types";
import { cloneDeep } from 'lodash';
import { iterateObjectRecursively } from "./pages/DetailsConfirmationPage";

/// In production fetch from 'giftagame.de', else from 'localhost:3001'
const BACKEND_URL: string = import.meta.env.MODE == "production" ? "https://giftagame.de/api/" : "http://localhost:3001/";


function getPath(endpoint: string): string {
    // ignore first '/'
    if (endpoint[0] === '/')
        endpoint = endpoint.slice(1);
  
    return BACKEND_URL + endpoint
}

export async function fetchImageWithoutBackground(file: File): Promise<File> {
    const endpoint = getPath('remove-bg');
    const formData = new FormData();

    // Send file as form data
    formData.append('image-with-bg', file);

    const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
    });
    const blob = await response.blob();

    // Convert back to file
    return new File([blob], file.name, {
        type: blob.type,
    });
}


/**
 * Let the server compile and return the id of this pack/game.
 */
export async function compilePackAndGame(options: ResourcePackOptions): Promise<ExportId> {
    /* Here we wil be doing the following
        1. Add each of the resources to a FormData object
        2. Add the options json object to the FormData object
        3. Send the FormData object to the server
        4. Display the response from the server
    */

    const endpoint = getPath("compile");
    const formData = new FormData();

    const copyOptions = cloneDeep(options);

    iterateObjectRecursively(copyOptions, (value) => {
        if (isMediaSourceResource(value)) {
            if (value.source instanceof File) {
                formData.append('file', value.source, value.source.name);
                (value as MediaFileNameResource).source = value.source.name;
            }
        }
    });

    // Add the options to the FormData object
    formData.append('options', JSON.stringify(copyOptions));

    // Send the FormData object to the server
    const result = await fetch(endpoint, {
        method: 'POST',
        body: formData,
    });

    const response = await result.json();

    return response.id
}

export function getGamePreviewPath(id: ExportId): string {
    return getPath(`game/${id}`);
}

