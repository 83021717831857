import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    ModalDialog,
} from '@mui/joy';
import { useState } from 'react';
import { VisuallyHiddenInput } from './MainCharacterEditorPage';
import { CachedTexture, useStore } from '../store';
import Texture from './Texture';
import Cropper, { Area } from 'react-easy-crop';
import { Check } from '@mui/icons-material';
import getCroppedImg from '../utils/CropImage';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';

function TextureDialog({
    open,
    onClose,
    onChange,
}: {
    open: boolean;
    onClose: () => void;
    onChange: (file: File) => void;
}) {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog size="lg">
                <TextureList onSelect={onChange} />
            </ModalDialog>
        </Modal>
    );
}

export function EditorDialog({
    file,
    onModifyTexture,
    onClose,
}: {
    file: File;
    onModifyTexture: (file: CachedTexture) => void;
    onClose: () => void;
}) {
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoom, onZoomChange] = useState(1);
    const [rotation, onRotationChange] = useState(0);

    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(
        null,
    );

    const imageUrl = URL.createObjectURL(file);

    return (
        <Modal open onClose={onClose}>
            <ModalDialog size="lg">
                <DialogTitle>Edit Your Texture</DialogTitle>
                <DialogContent>
                    <Box
                        style={{
                            position: 'relative',
                            height: '20em',
                        }}
                    >
                        <Cropper
                            image={imageUrl}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            onCropChange={onCropChange}
                            onZoomChange={onZoomChange}
                            onRotationChange={onRotationChange}
                            showGrid
                            onCropComplete={(
                                croppedArea,
                                croppedAreaPixels,
                            ) => {
                                setCroppedAreaPixels(croppedAreaPixels);
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        endDecorator={<Check />}
                        onClick={async () => {
                            const croppedImage = await getCroppedImg(
                                imageUrl,
                                croppedAreaPixels!,
                                rotation,
                            );

                            onModifyTexture({
                                file: croppedImage,
                                isBackgroundRemoved: false,
                            });

                            onClose();
                        }}
                    >
                        Apply Changes
                    </Button>
                    <IconButton onClick={() => onRotationChange(rotation + 90)}>
                        <Rotate90DegreesCwIcon />
                    </IconButton>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

function TextureList({
    onSelect,
}: {
    onSelect: (file: File) => void;
    onEdit: (file: File) => void;
}) {
    const { cachedTextures, addCachedTexture, modifyCachedTexture } =
        useStore();

    return (
        <>
            <DialogTitle>Your Textures</DialogTitle>
            <DialogContent>
                <Box
                    sx={(theme) => ({
                        display: 'grid',
                        gridTemplateColumns:
                            'repeat(auto-fill, minmax(100px, 1fr))', // Automatically adjust the number of columns
                        gap: theme.spacing(2),
                        width: '100%',
                    })}
                >
                    {cachedTextures.map((texture, index) => (
                        <Texture
                            key={index}
                            texture={{
                                source: texture.file,
                                type: 'png',
                            }}
                            size={'100px'}
                            onClick={() => onSelect(texture.file)}
                            onModifyTexture={(newTexture) => {
                                modifyCachedTexture(index, newTexture);
                            }}
                            showActionButtons={{
                                editTexture: true,
                                removeBackground: !texture.isBackgroundRemoved,
                            }}
                        />
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button component="label" role={undefined}>
                    Upload from computer
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        onChange={(event) =>
                            event.target.files !== null &&
                            [...event.target.files].forEach(async (file) => {
                                addCachedTexture(file);
                            })
                        }
                    />
                </Button>
            </DialogActions>
        </>
    );
}
export default function ExtendedImageUploadButton({
    onChange,
}: {
    onChange: (file: File) => void;
}) {
    // This component is a button that opens a Dialog when clicked
    // Where you can select files from your computer
    // or from a list of images that you have already uploaded
    // or from a list of images that are presets

    const [open, setOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setOpen(true)}>
                Change texture
            </Button>
            {open && (
                <TextureDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    onChange={onChange}
                />
            )}
        </>
    );
}
