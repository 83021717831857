import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { ResourcePackOptions } from './common/godot.types';
import { createJSONStorage } from 'zustand/middleware'

export interface CachedTexture {
    file: File;
    isBackgroundRemoved: boolean;
}

const defaultOptions: ResourcePackOptions = {
    enemies: [],
    player: null,
    world: {
        background: [],
        backgroundSound: [],
        groundTexture: null,
    },
};

type Store = {
    options: ResourcePackOptions;
    setPlayerTexture: (texture: File) => void;
    resetMainCharacterAvatar: () => void;
    addEnemy: (enemy: File, spawnProbability: number) => void;
    changeEnemyTexture: (index: number, texture: File) => void;
    changeEnemySpawnProbability: (
        index: number,
        spawnProbability: number,
    ) => void;
    deleteEnemy: (index: number) => void;
    cachedTextures: CachedTexture[];
    addCachedTexture: (texture: File) => void;
    deleteCachedTexture: (index: number) => void;
    modifyCachedTexture: (index: number, texture: CachedTexture) => void;
};

export const useStore = create<Store>()((set) => ({
    options: defaultOptions,
    cachedTextures: [],
    setPlayerTexture: (texture) =>
        set((state) => ({
            options: {
                ...state.options,
                player: { texture: { source: texture, type: 'png' } },
            },
        })),
    resetMainCharacterAvatar: () =>
        set((state) => ({ options: { ...state.options, player: null } })),
    addEnemy: (enemy: MediaSource, spawnProbability) => {
        set((state) => ({
            options: {
                ...state.options,
                enemies: [
                    ...state.options.enemies,
                    {
                        texture: { source: enemy, type: 'png' },
                        spawnProbability,
                    },
                ],
            },
        }));
    },
    changeEnemyTexture: (index: number, texture: File) => {
        set((state) => {
            const newEnemies = [...state.options.enemies];

            if (index >= newEnemies.length) {
                newEnemies.push({
                    texture: null,
                    spawnProbability: 0,
                    sound: null,
                });
            }

            newEnemies[index].texture = {
                source: texture,
                type: 'png',
            };
            return { options: { ...state.options, enemies: newEnemies } };
        });
    },
    changeEnemySpawnProbability: (index: number, spawnProbability: number) => {
        set((state) => {
            const newEnemies = [...state.options.enemies];

            if (index >= newEnemies.length) {
                newEnemies.push({
                    texture: null,
                    spawnProbability: 0,
                    sound: null,
                });
            }

            newEnemies[index].spawnProbability = spawnProbability;
            return { options: { ...state.options, enemies: newEnemies } };
        });
    },
    deleteEnemy: (index: number) => {
        set((state) => {
            const newEnemies = state.options.enemies.filter(
                (_, i) => i !== index,
            );
            return { options: { ...state.options, enemies: newEnemies } };
        });
    },
    addCachedTexture: (texture: File) => {
        set((state) => ({
            cachedTextures: [
                ...state.cachedTextures,
                { file: texture, isBackgroundRemoved: false },
            ],
        }));
    },
    deleteCachedTexture: (index: number) => {
        set((state) => {
            const newCachedTextures = state.cachedTextures.filter(
                (_, i) => i !== index,
            );
            return { cachedTextures: newCachedTextures };
        });
    },
    modifyCachedTexture: (index: number, texture: CachedTexture) => {
        set((state) => {
            const newCachedTextures = [...state.cachedTextures];
            newCachedTextures[index] = texture;
            return { cachedTextures: newCachedTextures };
        });
    },
}));

mountStoreDevtool('Store', useStore);
