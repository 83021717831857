import { MediaFileResource } from '../common/godot.types';
import {
    AspectRatio,
    AspectRatioProps,
    Box,
    IconButton,
    Tooltip,
} from '@mui/joy';
import { useMemo, useState } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CropIcon from '@mui/icons-material/Crop';
import { EditorDialog } from './ExtendedImageUploadButton';
import { CachedTexture } from '../store';
import { fetchImageWithoutBackground } from '../client';


export default function Texture({
    texture,
    size,
    alt,
    onModifyTexture,
    showActionButtons,
    ...rest
}: {
    texture: MediaFileResource | null | undefined;
    size: string;
    alt?: string;
    onModifyTexture?: (texture: CachedTexture) => void;
    showActionButtons?: {
        removeBackground: boolean;
        editTexture: boolean;
    };
} & AspectRatioProps) {
    const textureUrl = useMemo(() => {
        console.log('textureUrl', texture);
        return texture ? URL.createObjectURL(texture.source) : alt;
    }, [alt, texture]);
    const [showEditorDialog, setShowEditorDialog] = useState(false);
    const [isBackgroundRemovalPending, setIsBackgroundRemovalPending] = useState(false);

    const ICON_MARGIN = '0.2em'; // margin from wand icon to top and right border

    return (
        <>
            <AspectRatio
                ratio="4/4"
                variant="outlined"
                sx={{ width: size }}
                {...rest}
            >
                {texture ? (
                    <Box position="relative">
                        <img
                            src={textureUrl}
                            alt="Texture"
                            width={'100%'}
                            height="100%"
                        />
                        {showActionButtons && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: ICON_MARGIN,
                                    right: ICON_MARGIN,
                                    display: 'flex',
                                    gap: ICON_MARGIN,
                                }}
                            >
                                {showActionButtons.removeBackground && (
                                    <Tooltip
                                        variant="soft"
                                        title="Remove Background"
                                        size="sm"
                                        arrow
                                    >
                                        <IconButton
                                            loading={isBackgroundRemovalPending}
                                            style={{
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.8)',
                                            }}
                                            onClick={async (e) => {
                                                e.stopPropagation();

                                                if (
                                                    texture &&
                                                    onModifyTexture
                                                ) {
                                                    setIsBackgroundRemovalPending(
                                                        true,
                                                    );
                                                    try {
                                                        const modifiedTexture =
                                                            await fetchImageWithoutBackground(
                                                                texture.source,
                                                            );
                                                        console.log(
                                                            'modifiedTexture',
                                                            modifiedTexture,
                                                        );
                                                        onModifyTexture({
                                                            isBackgroundRemoved:
                                                                true,
                                                            file: modifiedTexture,
                                                        });
                                                    }finally {
                                                        setIsBackgroundRemovalPending(
                                                            false,
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            <AutoFixHighIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {showActionButtons.editTexture && (
                                    <Tooltip
                                        variant="soft"
                                        title="Edit Texture"
                                        size="sm"
                                        arrow
                                    >
                                        <IconButton
                                            style={{
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.8)',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowEditorDialog(true)
                                            }}
                                        >
                                            <CropIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        )}
                    </Box>
                ) : (
                    <ImageIcon sx={{ fontSize: '1rem', opacity: 0.2 }} />
                )}
            </AspectRatio>
            {showEditorDialog && (
                <EditorDialog
                    file={texture!.source}
                    onModifyTexture={onModifyTexture!}
                    onClose={() => setShowEditorDialog(false)}
                />
            )}
        </>
    );
}
